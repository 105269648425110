import React, { useState } from "react";
import {
  CenteredForm,
  FlexRowBetween,
  GenericButton,
  InputBox,
  RespFont,
} from "../../common/Style";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addGuest } from "../../../store/reducers/guestsReducer";
import OpacityLoading from "../../common/loading/OpacityLoading";
import Dropdown from "react-bootstrap/Dropdown";
import { addTodo, editTodo } from "../../../store/reducers/todosReducer";
import { toast } from "react-toastify";
import delete1 from "../../../assets/svgs/delete1.svg";
const Add = (props) => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [agent, setAgent] = useState(props.data.nickname);
  const [description, setDescription] = useState(props.data.description);
  const { user } = useSelector((state) => state.authReducer);
  const { authenticated } = useSelector((state) => state.authReducer);
  const { isPoints } = useSelector((state) => state.dataReducer);
  let widthS = window.innerWidth; // 1728

  const { todosLoading } = useSelector((state) => state.todosReducer);

  const onSubmit = async (data) => {
    if (agent === "") toast.error("Agent field is required");
    else {
      const body = {
        description: data.description,
        sum: parseFloat(data.sum),
        dateTime:
          data.dateTime === ""
            ? new Date().toISOString()
            : data.dateTime.toString(),
      };
      dispatch(editTodo(props.data._id, body)).then((res) => {
        if (!res.payload?.error) {
          toast.success("Successful!");
          props.init();
        } else console.log(res.payload?.error);
      });
    }
  };
  return (
    <CenteredForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      style={{
        paddingTop: "10px",
        paddingBottom: "10px",
        background: "#f2f2f2cc",
      }}
    >
      <RespFont
        pSize={"20px"}
        cSize={"22px"}
        style={{
          textAlign: "center",
          color: "#3A3C3F",
          fontWeight: "bold",
          padding: `30px 0`,
          paddingBottom: `10px`,
        }}
      >
        Edit Conversion
      </RespFont>

      <InputBox labelColor={"gray"} color={"#3A3C3F"}>
        <div className="relativeBox">
          <input
            value={agent}
            type="text"
            name="agent"
            disabled={true}
            minLength={2}
            maxLength={50}
            required={true}
            ref={register()}
          />
          <label
            style={{
              top: "-20px",
              fontSize: widthS > 768 ? "12px" : "11px",
              color: "gray",
            }}
          >
            agent
          </label>
        </div>

        <div className="relativeBox">
          <input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            name="description"
            minLength={2}
            maxLength={50}
            required={true}
            ref={register()}
          />
          <label>description</label>
          <Dropdown
            style={{
              position: "absolute",
              right: "0px",
              top: "16px",
            }}
          >
            <Dropdown.Toggle
              style={{
                background: "#527bfe",
                zIndex: 1,
                border: "none",
                height: "26px",
                width: "20px",
                borderRadius: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              id="dropdown-basic"
            ></Dropdown.Toggle>
            <Dropdown.Menu style={{ textAlign: "left" }}>
              <Dropdown.Item onClick={() => setDescription("deposit")}>
                {"deposit"}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setDescription("withdrawal")}>
                {"withdrawal"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="relativeBox">
          <input
            type="number"
            name="sum"
            defaultValue={props.data.sum}
            required={true}
            min={-100000000000}
            max={100000000000}
            ref={register()}
          />
          <label>
            sum
            {authenticated
              ? user.isPoints
                ? ""
                : "($)"
              : isPoints
              ? ""
              : " ($)"}
          </label>
        </div>

        <div className="relativeBox">
          <input
            type="datetime-local"
            name="dateTime"
            ref={register()}
            defaultValue={props.data.dateTime.slice(
              0,
              new Date().toISOString().length - 8
            )}
            // value="2018-06-12T19:30"
          />
          <label>time</label>
        </div>
        <FlexRowBetween style={{ width: "60%", margin: "30px auto" }}>
          {/* <div style={{ paddingBottom: "10px" }}> */}
          <GenericButton
            type="submit"
            style={{ width: widthS > 768 ? "80%" : "73%" }}
          >
            Submit
          </GenericButton>
          <div
            onClick={() => {
              props.init(false);
              props.setShowDeleteConversion(props.data._id);
            }}
            style={{
              backgroundColor: "#3a3c3f",
              borderRadius: "3px",
              cursor: "pointer",
            }}
          >
            <img
              src={delete1}
              alt="delete"
              style={{ width: widthS > 768 ? "36px" : "33px", padding: "7px" }}
            />
          </div>
          {/* </div> */}
        </FlexRowBetween>
      </InputBox>
      {todosLoading && <OpacityLoading />}
    </CenteredForm>
  );
};

export default Add;
