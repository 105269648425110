import { createSlice } from "@reduxjs/toolkit";
import { post2, get2 } from "../api/http";
import ServerRoutes from "../api/routes";

const initialState = {
  data: {},
  msg: "Morning Monsters",
  isPoints: false,
  userId: null,
  userPass: null,
  index: 0,
  dataError: null,
  dataLoading: false,
};

const _setData = (state, action) => {
  state.userId = action.payload.id;
  if (state.data?._id != action.payload.data._id) {
    state.index = state.index + 1;
    state.data = action.payload.data;
  }
  state.msg = action.payload.msg;
  state.isPoints = action.payload.isPoints;
};

const _setUserPass = (state, action) => {
  state.userPass = action.payload;
};

const _actionStart = (state) => {
  state.dataLoading = true;
  state.dataError = null;
};

const _actionSuccess = (state) => {
  state.dataLoading = false;
  state.dataError = null;
};

const _actionFailed = (state, action) => {
  state.dataLoading = false;
  state.dataError = action.payload.error;
};

const _cleanDataState = (state) => {
  state.data = {};
  state.dataError = null;
  state.dataLoading = false;
};

const data = createSlice({
  name: "data",
  initialState,
  reducers: {
    cleanDataState: _cleanDataState,
    actionStart: _actionStart,
    actionFailed: _actionFailed,
    setData: _setData,
    actionSuccess: _actionSuccess,
    setUserPass: _setUserPass,
  },
});

const { reducer, actions } = data;

const {
  actionStart,
  actionFailed,
  actionSuccess,
  setData,
  cleanDataState,
  setUserPass,
} = actions;

export default reducer;

export const getData = (uid) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(
      ServerRoutes.Data.getDataByUid,
      { uid }
    );
    if (status !== 200) {
      return dispatch(actionFailed({ error }));
    } else {
      dispatch(actionSuccess());
      return dispatch(setData(data));
    }
  };
};

export const cleanAll = () => {
  return async (dispatch) => {
    return dispatch(cleanDataState());
  };
};

export const setUserPassReduxOnly = (pass) => {
  return async (dispatch) => {
    return dispatch(setUserPass(pass));
  };
};
