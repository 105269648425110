import React, { useState } from "react";
import {
  CenteredForm,
  GenericButton,
  InputBox,
  RespFont,
} from "../../common/Style";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addGuest } from "../../../store/reducers/guestsReducer";
import OpacityLoading from "../../common/loading/OpacityLoading";
const Add = (props) => {
  const { user } = useSelector((state) => state.authReducer);
  const { guestsLoading } = useSelector((state) => state.guestsReducer);
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const { authenticated } = useSelector((state) => state.authReducer);
  const { isPoints } = useSelector((state) => state.dataReducer);

  const onSubmit = async (data) => {
    const body = {
      nickname: data.nickname,
      pic: data.pic,
      monthlyTarget: data.monthlyTarget,
    };
    dispatch(addGuest(body)).then((res) => {
      if (!res.payload?.error) {
        toast.success("Successful!");
        props.init();
      }
    });
  };
  return (
    <CenteredForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      style={{
        paddingTop: "10px",
        paddingBottom: "10px",
        background: "#f2f2f2cc",
      }}
    >
      <RespFont
        pSize={"20px"}
        cSize={"22px"}
        style={{
          textAlign: "center",
          color: "#3A3C3F",
          fontWeight: "bold",
          padding: `30px 0`,
          paddingBottom: `10px`,
        }}
      >
        New Agent
      </RespFont>

      <InputBox labelColor={"gray"} color={"#3A3C3F"}>
        <div className="relativeBox">
          <input
            type="text"
            name="nickname"
            required={true}
            minLength={2}
            maxLength={50}
            ref={register()}
          />
          <label>nickname</label>
        </div>

        <div className="relativeBox">
          <input
            type="number"
            name="monthlyTarget"
            required={true}
            min={1}
            max={100000000000}
            ref={register()}
          />
          <label>
            monthly goal
            {authenticated
              ? user.isPoints
                ? ""
                : "($)"
              : isPoints
              ? ""
              : " ($)"}
          </label>
        </div>

        <div className="relativeBox">
          <input
            type="text"
            name="pic"
            // minLength={2}
            // maxLength={100}
            ref={register()}
          />
          <label>picture url</label>
        </div>

        <div style={{ paddingBottom: "10px" }}>
          <GenericButton
            type="submit"
            style={{ width: "60%", margin: "30px auto" }}
          >
            Submit
          </GenericButton>
        </div>
      </InputBox>
      {guestsLoading && <OpacityLoading />}
    </CenteredForm>
  );
};

export default Add;
