import React, { useEffect, useMemo, useState } from "react";
import GenericModal from "../../common/modals/GenericModal";
import {
  DisplayNoneComputer,
  DisplayNonePhone,
  FlexRowBetween,
  FlexRowCulumnBetween,
  GenericButton,
  GuestRowStyle,
  MainDiv,
  RespFont,
} from "../../common/Style";
import LogOutAll from "../../common/modals/LogOutAll";
import plus from "../../../assets/svgs/plus.svg";
import settings from "../../../assets/svgs/settings.svg";
import Add from "./Add";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanAll,
  deleteOneGuest,
  getGuests,
} from "../../../store/reducers/guestsReducer";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { toast } from "react-toastify";
import AgentRowC from "./AgentRowC";
import Edit from "./Edit";
import ClientRoutes from "../../../navigation/Routes";
import { useHistory } from "react-router-dom";
import OpacityLoading from "../../common/loading/OpacityLoading";
import YesNoModal from "../../common/modals/YesNoModal";
import downArrowB from "../../../assets/svgs/downArrowB.svg";
import downArrowW from "../../../assets/svgs/downArrowW.svg";
import upArrowB from "../../../assets/svgs/upArrowB.svg";
import upArrowW from "../../../assets/svgs/upArrowW.svg";
import AgentRowP from "./AgentRowP";
import EditDetails from "../EditDetails";

const TableScore = (props) => {
  let history = useHistory();
  const { guests, guestsError, guestsLoading } = useSelector(
    (state) => state.guestsReducer
  );

  const { authenticated } = useSelector((state) => state.authReducer);
  const { isPoints } = useSelector((state) => state.dataReducer);
  const { user } = useSelector((state) => state.authReducer);
  let widthS = window.innerWidth; // 1728

  const [sortBy, setSortBy] = useState("monthlyScore");
  const [sortDirection, setSortDirection] = useState(true);
  const [showEditAgent, setShowEditAgent] = useState(false);
  const [showDeleteAgent, setShowDeleteAgent] = useState(false);

  const dispatch = useDispatch();
  const [showSignOut, setShowSignOut] = useState(false);
  const [showAddAgent, setShowAddAgent] = useState(false);
  const [showEditDetails, setShowEditDetails] = useState(false);
  const getAll = () => dispatch(getGuests());

  useEffect(() => {
    getAll();
    return () => {
      dispatch(cleanAll());
    };
  }, []);

  useEffect(() => {
    if (guestsError) toast.error(guestsError);
  }, [guestsError]);

  let guestsArray = useMemo(() => {
    let arr = [];
    let dailyTotal = 0;
    let monthlyTotal = 0;
    let sortedArray = [];
    if (Object.keys(guests).length > 0) {
      for (const [key, value] of Object.entries(guests))
        sortedArray.push(value);

      switch (sortBy) {
        case "ratio":
          if (sortDirection) {
            sortedArray.sort(
              (a, b) =>
                b["monthlyScore"] / b["monthlyTarget"] -
                a["monthlyScore"] / a["monthlyTarget"]
            );
          } else
            sortedArray.sort(
              (a, b) =>
                a["monthlyScore"] / a["monthlyTarget"] -
                b["monthlyScore"] / b["monthlyTarget"]
            );
          break;
        default:
          if (sortDirection) sortedArray.sort((a, b) => b[sortBy] - a[sortBy]);
          else sortedArray.sort((a, b) => a[sortBy] - b[sortBy]);
      }
      for (let i = 0; i < sortedArray.length; i++) {
        dailyTotal += sortedArray[i]["dailyScore"];
        monthlyTotal += sortedArray[i]["monthlyScore"];
        arr.push(
          widthS > 768 ? (
            <AgentRowC
              key={i}
              setEdit={setShowEditAgent}
              data={sortedArray[i]}
            />
          ) : (
            <AgentRowP
              key={i}
              setEdit={setShowEditAgent}
              data={sortedArray[i]}
            />
          )
        );
      }
    }
    return [arr, dailyTotal, monthlyTotal];
  }, [guests, sortBy, sortDirection]);

  const deleteConversionFunc = async () => {
    dispatch(deleteOneGuest(showDeleteAgent)).then((res) => {
      if (!res.payload?.error) {
        toast.success("Successful!");
      }
    });
  };

  return (
    <MainDiv className="App">
      <FlexRowBetween style={{ margin: "20px auto", position: "relative" }}>
        <FlexRowBetween
          style={{ justifyContent: "flex-start", width: "min-content" }}
        >
          <GenericButton
            onClick={() => setShowAddAgent(true)}
            style={{ marginLeft: "20px", width: "max-content" }}
          >
            Add <img src={plus} style={{ width: "20px", marginLeft: "10px" }} />
          </GenericButton>
          <GenericButton
            onClick={() => history.push(ClientRoutes.User.conversions)}
            style={{ marginLeft: "20px", width: "max-content" }}
          >
            Conversions
          </GenericButton>
          <DisplayNonePhone>
            <GenericButton
              onClick={() => setShowEditDetails(true)}
              style={{
                marginLeft: "20px",
                width: "max-content",
                padding: "6px 9px",
              }}
            >
              <img
                src={settings}
                style={{ width: widthS > 768 ? "24px" : "21px" }}
              />
            </GenericButton>
          </DisplayNonePhone>
        </FlexRowBetween>
        <DisplayNonePhone
          // style={{ position: "absolute", top: "20px", right: "50%" }}
          style={{
            width: "min-content",
            position: "absolute",
            right: "51.15%",
          }}
        >
          <FlexRowBetween
            style={{
              width: widthS > 768 ? "100%" : "90%",
              margin: "15px auto",
              // marginTop: widthS > 768 ? "15px" : "15px",
              marginTop: "15px",
              justifyContent: "flex-start",
              // flexWrap: "wrap",
            }}
          >
            <FlexRowBetween
              style={{
                width: "min-content",
                border: "2px solid rgba(0, 0, 0, 0.11)",
                borderRadius: "60px",
                padding: widthS > 768 ? "5px" : "2px",
                fontWeight: "700",
                // marginRight: "2.5%",
              }}
            >
              <RespFont
                pSize={"12px"}
                onClick={() => setSortBy("dailyScore")}
                style={{
                  padding: widthS > 768 ? "7px 12px" : "4px 8px",
                  cursor: "pointer",
                  backgroundColor:
                    sortBy === "dailyScore" ? "#527bfe" : "inherit",
                  transition: "all 0.2s",
                  color: sortBy === "dailyScore" ? "#fff" : "#3A3C3F",
                  borderRadius: "60px",
                  width: "max-content",
                }}
              >
                {user?.isPoints ? "Daily FTD" : "Daily score"}
              </RespFont>
              <RespFont
                pSize={"12px"}
                onClick={() => setSortBy("monthlyScore")}
                style={{
                  padding: widthS > 768 ? "7px 12px" : "4px 8px",
                  cursor: "pointer",
                  backgroundColor:
                    sortBy === "monthlyScore" ? "#527bfe" : "inherit",
                  transition: "all 0.2s",
                  color: sortBy === "monthlyScore" ? "#fff" : "#3A3C3F",
                  borderRadius: "60px",
                  width: "max-content",
                }}
              >
                {user?.isPoints ? "Monthly FTD" : "Monthly score"}
              </RespFont>
              <RespFont
                pSize={"12px"}
                onClick={() => setSortBy("ratio")}
                style={{
                  padding: widthS > 768 ? "7px 12px" : "4px 8px",
                  cursor: "pointer",
                  backgroundColor: sortBy === "ratio" ? "#527bfe" : "inherit",
                  transition: "all 0.2s",
                  color: sortBy === "ratio" ? "#fff" : "#3A3C3F",
                  borderRadius: "60px",
                  width: "max-content",
                }}
              >
                Ratio
              </RespFont>
            </FlexRowBetween>

            <FlexRowBetween
              style={{
                width: "min-content",
                border: "2px solid rgba(0, 0, 0, 0.11)",
                borderRadius: "5px",
                padding: "3px",
                fontWeight: "700",
                marginLeft: widthS > 768 ? "20px" : "10px",
              }}
            >
              <RespFont
                onClick={() => setSortDirection(true)}
                style={{
                  padding: widthS > 768 ? "3px 5px" : "0px 4px",
                  cursor: "pointer",
                  backgroundColor: sortDirection ? "#527bfe" : "inherit",
                  transition: "all 0.2s",
                  color: sortDirection ? "#fff" : "#3A3C3F",
                  borderRadius: "3px",
                  width: "max-content",
                }}
              >
                <img
                  src={sortDirection ? upArrowW : upArrowB}
                  style={{ width: widthS > 768 ? "22px" : "13px" }}
                />
              </RespFont>
              <RespFont
                onClick={() => setSortDirection(false)}
                style={{
                  padding: widthS > 768 ? "3px 5px" : "0px 4px",
                  cursor: "pointer",
                  backgroundColor: !sortDirection ? "#527bfe" : "inherit",
                  transition: "all 0.2s",
                  color: !sortDirection ? "#fff" : "#3A3C3F",
                  borderRadius: "3px",
                  width: "max-content",
                }}
              >
                <img
                  src={sortDirection ? downArrowB : downArrowW}
                  style={{ width: widthS > 768 ? "22px" : "13px" }}
                />
              </RespFont>
            </FlexRowBetween>
          </FlexRowBetween>
        </DisplayNonePhone>
        <GenericButton
          onClick={() => setShowSignOut(true)}
          style={{ marginRight: "20px" }}
        >
          Sign Out
        </GenericButton>
      </FlexRowBetween>
      <FlexRowCulumnBetween style={{ margin: "0px auto" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: widthS > 768 ? "50%" : "100%",
          }}
        >
          <div
            style={{
              width: widthS > 768 ? "130%" : "100%",
              height: "100%",
              margin: "0 0",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {widthS > 768 ? (
              <GuestRowStyle
                style={{
                  margin: "10px auto",
                  boxShadow: `0 0px 2px #527bfe`,
                }}
                background={"rgba(255,255,255,0.7)"}
              >
                <FlexRowBetween
                  style={{
                    padding: `10px 10px`,
                    cursor: "pointer",
                    textAlign: "left",
                  }}
                >
                  <div
                    style={{
                      color: "#739bf2",
                      fontWeight: "600",
                      width: "25%",
                      textAlign: "left",
                      // marginLeft: "25px",
                    }}
                  >
                    Agent
                  </div>
                  <div style={{ width: "15%", marginLeft: "70px" }}>
                    {user?.isPoints ? "Daily FTD" : "Daily score"}
                  </div>
                  <div style={{ width: "15%" }}>
                    {user?.isPoints ? "Monthly FTD" : "Monthly score"}
                  </div>
                  <div style={{ width: "20%" }}>Monthly target</div>
                  <div style={{ width: "120px", textAlign: "center" }}>
                    Ratio
                  </div>
                </FlexRowBetween>
              </GuestRowStyle>
            ) : (
              <GuestRowStyle
                style={{
                  margin: "10px auto",
                  marginTop: "0",
                  boxShadow: `0 0px 2px ${user.color}`,
                }}
                background={"rgba(255,255,255,0.7)"}
              >
                <FlexRowBetween
                  style={{
                    padding: `10px 10px`,
                    cursor: "pointer",
                    textAlign: "left",
                  }}
                >
                  <div
                    style={{
                      color: "#739bf2",
                      fontWeight: "600",
                      width: "35%",
                    }}
                  >
                    Agent
                  </div>
                  <div style={{ width: "35%" }}>
                    <div>{user?.isPoints ? "Daily FTD" : "Daily score"}</div>
                    <div>
                      {user?.isPoints ? "Monthly FTD" : "Monthly score"}
                    </div>
                  </div>
                  <div style={{ width: "30%", textAlign: "center" }}>
                    <div>Monthly target</div>
                    <div style={{ textAlign: "center" }}>Ratio</div>
                  </div>
                </FlexRowBetween>
              </GuestRowStyle>
            )}

            {guestsArray[0]}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            width: widthS > 768 ? "35%" : "100%",
          }}
        >
          <DisplayNonePhone style={{ fontSize: "30px", fontWeight: "700" }}>
            {user?.isPoints ? "Conversion" : "Retention"}
          </DisplayNonePhone>
          <DisplayNonePhone
            style={{ fontSize: "22px", fontWeight: "600", color: "#739bf2" }}
          >
            {user.msg}
          </DisplayNonePhone>
          <FlexRowBetween
            style={{
              width: widthS > 768 ? "100%" : "90%",
              marginLeft: widthS > 768 ? "0" : "5%",
              justifyContent: "center",
              marginTop: widthS > 768 ? "15px" : "0px",
            }}
          >
            Summary Sales:
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>{"Daily total"}</Tooltip>}
            >
              <RespFont
                // pSize={"12px"}
                style={{
                  width: "max-content",
                  marginLeft: "15px",
                  // marginLeft: widthS > 768 ? "15px" : "0px",
                  padding: widthS > 768 ? "5px 10px" : "3px 7px",
                  borderRadius: "5px",
                  boxShadow: "0 1px 4px silver",
                  color:
                    guestsArray[1] > 0
                      ? "#017c64"
                      : guestsArray[1] < 0
                      ? "#cb2e50"
                      : "black",
                  backgroundColor:
                    guestsArray[1] > 0
                      ? "#e8f8f5"
                      : guestsArray[1] < 0
                      ? "#f9eaee"
                      : "#fff",
                }}
              >
                <b>Today</b>: {guestsArray[1]}
                {authenticated
                  ? user.isPoints
                    ? ""
                    : "$"
                  : isPoints
                  ? ""
                  : "$"}
              </RespFont>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>{"Monthly total"}</Tooltip>}
            >
              <RespFont
                // pSize={"12px"}
                style={{
                  width: "max-content",
                  marginLeft: "15px",
                  // marginTop: widthS > 768 ? "10px" : "15px",
                  padding: widthS > 768 ? "5px 10px" : "3px 7px",
                  borderRadius: "5px",
                  boxShadow: "0 1px 4px silver",
                  color:
                    guestsArray[2] > 0
                      ? "#017c64"
                      : guestsArray[2] < 0
                      ? "#cb2e50"
                      : "black",
                  backgroundColor:
                    guestsArray[2] > 0
                      ? "#e8f8f5"
                      : guestsArray[2] < 0
                      ? "#f9eaee"
                      : "#fff",
                }}
              >
                <b>Monthly</b>: {guestsArray[2]}
                {authenticated
                  ? user.isPoints
                    ? ""
                    : "$"
                  : isPoints
                  ? ""
                  : "$"}
              </RespFont>
            </OverlayTrigger>
          </FlexRowBetween>
          <DisplayNoneComputer>
            <FlexRowBetween
              style={{
                width: widthS > 768 ? "100%" : "90%",
                margin: "15px auto",
                marginTop: "15px",
                justifyContent: "flex-start",
              }}
            >
              <FlexRowBetween
                style={{
                  width: "min-content",
                  border: "2px solid rgba(0, 0, 0, 0.11)",
                  borderRadius: "60px",
                  padding: widthS > 768 ? "5px" : "2px",
                  fontWeight: "700",
                  // marginRight: "2.5%",
                }}
              >
                <RespFont
                  pSize={"12px"}
                  onClick={() => setSortBy("dailyScore")}
                  style={{
                    padding: widthS > 768 ? "7px 12px" : "4px 8px",
                    cursor: "pointer",
                    backgroundColor:
                      sortBy === "dailyScore" ? "#527bfe" : "inherit",
                    transition: "all 0.2s",
                    color: sortBy === "dailyScore" ? "#fff" : "#3A3C3F",
                    borderRadius: "60px",
                    width: "max-content",
                  }}
                >
                  {user?.isPoints ? "Daily FTD" : "Daily score"}
                </RespFont>
                <RespFont
                  pSize={"12px"}
                  onClick={() => setSortBy("monthlyScore")}
                  style={{
                    padding: widthS > 768 ? "7px 12px" : "4px 8px",
                    cursor: "pointer",
                    backgroundColor:
                      sortBy === "monthlyScore" ? "#527bfe" : "inherit",
                    transition: "all 0.2s",
                    color: sortBy === "monthlyScore" ? "#fff" : "#3A3C3F",
                    borderRadius: "60px",
                    width: "max-content",
                  }}
                >
                  {user?.isPoints ? "Monthly FTD" : "Monthly score"}
                </RespFont>
                <RespFont
                  pSize={"12px"}
                  onClick={() => setSortBy("ratio")}
                  style={{
                    padding: widthS > 768 ? "7px 12px" : "4px 8px",
                    cursor: "pointer",
                    backgroundColor: sortBy === "ratio" ? "#527bfe" : "inherit",
                    transition: "all 0.2s",
                    color: sortBy === "ratio" ? "#fff" : "#3A3C3F",
                    borderRadius: "60px",
                    width: "max-content",
                  }}
                >
                  Ratio
                </RespFont>
              </FlexRowBetween>

              <FlexRowBetween
                style={{
                  width: "min-content",
                  border: "2px solid rgba(0, 0, 0, 0.11)",
                  borderRadius: "5px",
                  padding: "3px",
                  fontWeight: "700",
                  marginLeft: widthS > 768 ? "20px" : "10px",
                }}
              >
                <RespFont
                  onClick={() => setSortDirection(true)}
                  style={{
                    padding: widthS > 768 ? "3px 5px" : "0px 4px",
                    cursor: "pointer",
                    backgroundColor: sortDirection ? "#527bfe" : "inherit",
                    transition: "all 0.2s",
                    color: sortDirection ? "#fff" : "#3A3C3F",
                    borderRadius: "3px",
                    width: "max-content",
                  }}
                >
                  <img
                    src={sortDirection ? upArrowW : upArrowB}
                    style={{ width: widthS > 768 ? "22px" : "13px" }}
                  />
                </RespFont>
                <RespFont
                  onClick={() => setSortDirection(false)}
                  style={{
                    padding: widthS > 768 ? "3px 5px" : "0px 4px",
                    cursor: "pointer",
                    backgroundColor: !sortDirection ? "#527bfe" : "inherit",
                    transition: "all 0.2s",
                    color: !sortDirection ? "#fff" : "#3A3C3F",
                    borderRadius: "3px",
                    width: "max-content",
                  }}
                >
                  <img
                    src={sortDirection ? downArrowB : downArrowW}
                    style={{ width: widthS > 768 ? "22px" : "13px" }}
                  />
                </RespFont>
              </FlexRowBetween>
            </FlexRowBetween>
          </DisplayNoneComputer>
        </div>
      </FlexRowCulumnBetween>
      {showSignOut && (
        <GenericModal
          init={setShowSignOut}
          div={<LogOutAll init={setShowSignOut} />}
        />
      )}

      {showAddAgent && (
        <GenericModal
          init={setShowAddAgent}
          div={<Add init={setShowAddAgent} />}
        />
      )}
      {showEditAgent && (
        <GenericModal
          init={setShowEditAgent}
          div={
            <Edit
              setShowDeleteAgent={setShowDeleteAgent}
              data={showEditAgent}
              init={setShowEditAgent}
            />
          }
        />
      )}
      {showDeleteAgent && (
        <GenericModal
          init={setShowDeleteAgent}
          div={
            <YesNoModal
              color={"#527bfe"}
              title={"Delete Agent"}
              body={"Are you sure?"}
              no={setShowDeleteAgent}
              yes={deleteConversionFunc}
            />
          }
        />
      )}
      {showEditDetails && (
        <GenericModal
          init={setShowEditDetails}
          div={<EditDetails init={setShowEditDetails} />}
        />
      )}

      {guestsLoading && <OpacityLoading />}
    </MainDiv>
  );
};

export default TableScore;
