import React, { useRef, useEffect } from "react";
import { CenteredForm, RespFont } from "../../common/Style";
import failureAudio from "../../../assets/audio/failureAudio.wav";
import successAudio from "../../../assets/audio/successAudio.mp3";
import success1 from "../../../assets/gifs/success1.gif";
import failure1 from "../../../assets/gifs/failure.gif";
import { commafy } from "../others/Functions";
import { useSelector } from "react-redux";
const Event = ({ name, amount }) => {
  const { isPoints } = useSelector((state) => state.dataReducer);
  const { user, authenticated } = useSelector((state) => state.authReducer);
  return (
    <CenteredForm
      onClick={(e) => e.stopPropagation()}
      style={{
        paddingTop: "10px",
        paddingBottom: "10px",
        background: "#fff",
      }}
    >
      <RespFont
        pSize={"80px"}
        cSize={"25px"}
        style={{ color: "#739bf2", marginTop: "20px" }}
      >
        {name}
      </RespFont>
      <RespFont
        pSize={"120px"}
        cSize={"35px"}
        style={{
          color: amount > 0 ? "green" : "red",
          fontWeight: "700",
          marginTop: "20px",
        }}
      >
        {commafy(amount)}
        {authenticated ? (user.isPoints ? "" : "$") : isPoints ? "" : "$"}
      </RespFont>
      <img
        src={amount > 0 ? success1 : failure1}
        style={{ width: "500px", margin: "30px auto" }}
      />
    </CenteredForm>
  );
};

export default Event;
