import React, { useState } from "react";
import {
  CenteredForm,
  FlexRowBetween,
  GenericButton,
  InputBox,
  RespFont,
} from "../../common/Style";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addGuest, editGuest1 } from "../../../store/reducers/guestsReducer";
import OpacityLoading from "../../common/loading/OpacityLoading";
import Dropdown from "react-bootstrap/Dropdown";
import { addTodo, editTodo } from "../../../store/reducers/todosReducer";
import { toast } from "react-toastify";
import delete1 from "../../../assets/svgs/delete1.svg";
const Edit = (props) => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const { authenticated } = useSelector((state) => state.authReducer);
  const { isPoints } = useSelector((state) => state.dataReducer);
  const [agent, setAgent] = useState(props.data.nickname);
  const [description, setDescription] = useState(props.data.description);
  const { user } = useSelector((state) => state.authReducer);
  let widthS = window.innerWidth; // 1728

  const { guestsLoading } = useSelector((state) => state.guestsReducer);

  const onSubmit = async (data) => {
    if (agent === "") toast.error("Agent field is required");
    else {
      const body = {
        nickname: data.nickname,
        monthlyTarget: data.monthlyTarget,
        pic: data.pic,
      };
      dispatch(editGuest1(props.data._id, body)).then((res) => {
        if (!res.payload?.error) {
          toast.success("Successful!");
          props.init();
        } else console.log(res.payload?.error);
      });
    }
  };
  return (
    <CenteredForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      style={{
        paddingTop: "10px",
        paddingBottom: "10px",
        background: "#f2f2f2cc",
      }}
    >
      <RespFont
        pSize={"20px"}
        cSize={"22px"}
        style={{
          textAlign: "center",
          color: "#3A3C3F",
          fontWeight: "bold",
          padding: `30px 0`,
          paddingBottom: `10px`,
        }}
      >
        Edit Agent
      </RespFont>

      <InputBox labelColor={"gray"} color={"#3A3C3F"}>
        <div className="relativeBox">
          <input
            defaultValue={props.data.nickname}
            type="text"
            name="nickname"
            minLength={2}
            maxLength={50}
            required={true}
            ref={register()}
          />
          <label>nickname</label>
        </div>

        <div className="relativeBox">
          <input
            type="number"
            name="monthlyTarget"
            defaultValue={props.data.monthlyTarget}
            required={true}
            min={1}
            max={100000000000}
            ref={register()}
          />
          <label>
            monthly goal
            {authenticated
              ? user.isPoints
                ? ""
                : "($)"
              : isPoints
              ? ""
              : " ($)"}
          </label>
        </div>

        <div className="relativeBox">
          <input
            type="text"
            name="pic"
            defaultValue={props.data.pic}
            // minLength={2}
            // maxLength={100}
            ref={register()}
          />
          <label>picture url</label>
        </div>

        <FlexRowBetween style={{ width: "60%", margin: "30px auto" }}>
          {/* <div style={{ paddingBottom: "10px" }}> */}
          <GenericButton
            type="submit"
            style={{ width: widthS > 768 ? "80%" : "73%" }}
          >
            Submit
          </GenericButton>
          <div
            onClick={() => {
              props.init(false);
              props.setShowDeleteAgent(props.data._id);
            }}
            style={{
              backgroundColor: "#3a3c3f",
              borderRadius: "3px",
              cursor: "pointer",
            }}
          >
            <img
              src={delete1}
              alt="delete"
              style={{ width: widthS > 768 ? "36px" : "33px", padding: "7px" }}
            />
          </div>
          {/* </div> */}
        </FlexRowBetween>
      </InputBox>
      {guestsLoading && <OpacityLoading />}
    </CenteredForm>
  );
};

export default Edit;
