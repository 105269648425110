const ServerRoutes = {
  Auth: {
    editMySelf: "user/editMySelf",
    editColorOnly: "user/editColorOnly",
    editTodos: "user/editTodos",
    logoutAll: "auth/logoutAll",
    logoutSpecific: "auth/logoutSpecific",
    login: "auth/login",
    checkAuth: "auth/checkAuth",
    register: "auth/register",
  },
  Data: {
    getDataByUid: "data/getDataByUid",
  },
  Todos: {
    delete: "todos/delete",
    add: "todos/add",
    getAll: "todos/getAll",
    getBetweenDates: "todos/getBetweenDates",
    edit: "todos/edit/",
    editStatus: "todos/editStatus/",
  },
  Guests: {
    getAll: "guests/getGuests",
    getGuestsByUid: "guests/getGuestsByUid",
    getGuest: "guests/getGuest",
    add: "dishes/add",
    editById: "guests/editGuest/",
    deleteGuest: "guests/deleteGuest",
    delete: "guests/delete",
    edit: "guests/edit/",
    addGuest: "guests/addGuest",
    deleteMany: "guests/deleteMany/",
    addMany: "guests/addMany",
  },
};

export default ServerRoutes;
