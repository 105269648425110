import React, { useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector, useDispatch } from "react-redux";
import { checkAuth } from "../store/reducers/authReducer";
import { ToastProvider } from "react-toast-notifications";
import UnAuthSwitch from "../navigation/UnAuthSwitch";
import UserSwitch from "../navigation/UserSwitch";
// import FilledLoading from "../components/common/loader/FilledLoading";
import "react-image-crop/dist/ReactCrop.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  const { user, authenticated, authLoading } = useSelector(
    (state) => state.authReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) dispatch(checkAuth());
  }, [authenticated]);

  let view = <UnAuthSwitch />;
  if (authenticated && user && user.role == 2 && !authLoading)
    view = <UserSwitch />;
  // else if (!authenticated && !authLoading) view = <UnAuthSwitch />;

  return (
    <div className="App animate__animated animate__fadeIn">
      {view}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="light"
      />
      {/* {authLoading && <FilledLoading background={user ? user.color : null} />} */}
    </div>
  );
}

export default App;
