import React, { useEffect, useMemo, useState } from "react";
import GenericModal from "../../common/modals/GenericModal";
import { FlexRowBetween, GenericButton, MainDiv } from "../../common/Style";
import LogOutAll from "../../common/modals/LogOutAll";
import plus from "../../../assets/svgs/plus.svg";
import Add from "./Add.js";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Edit from "./Edit";
import ConversionRow from "./ConversionRow";
import ClientRoutes from "../../../navigation/Routes";
import { useHistory } from "react-router-dom";
import OpacityLoading from "../../common/loading/OpacityLoading";
import {
  getTodos,
  cleanAll,
  deleteTodo,
} from "../../../store/reducers/todosReducer";
import YesNoModal from "../../common/modals/YesNoModal";

const Conversions = (props) => {
  let history = useHistory();
  const { todos, todosError, todosLoading } = useSelector(
    (state) => state.todosReducer
  );
  const { user } = useSelector((state) => state.authReducer);
  const { authenticated } = useSelector((state) => state.authReducer);
  const { isPoints } = useSelector((state) => state.dataReducer);

  const [sortBy, setSortBy] = useState("dateTime");
  const [sortDirection, setSortDirection] = useState(true);
  const [showEditAgent, setShowEditAgent] = useState(false);
  const [showDeleteConversion, setShowDeleteConversion] = useState(false);

  const dispatch = useDispatch();
  const [showSignOut, setShowSignOut] = useState(false);
  const [showAddAgent, setShowAddAgent] = useState(false);
  const getAll = () => dispatch(getTodos());

  useEffect(() => {
    getAll();
    return () => {
      dispatch(cleanAll());
    };
  }, []);

  useEffect(() => {
    if (todosError) toast.error(todosError);
  }, [todosError]);

  let todosArray = useMemo(() => {
    let arr = [];
    let countDeposit = 0;
    let countWithdrawal = 0;
    let sortedArray = [];
    if (Object.keys(todos).length > 0) {
      for (const [key, value] of Object.entries(todos)) sortedArray.push(value);
      switch (sortBy) {
        case "dateTime":
          if (sortDirection)
            sortedArray.sort(
              (a, b) => new Date(b[sortBy]) - new Date(a[sortBy])
            );
          else
            sortedArray.sort(
              (a, b) => new Date(a[sortBy]) - new Date(b[sortBy])
            );
          break;
        default:
          if (sortDirection) sortedArray.sort((a, b) => b[sortBy] - a[sortBy]);
          else sortedArray.sort((a, b) => a[sortBy] - b[sortBy]);
      }
      for (let i = 0; i < sortedArray.length; i++) {
        if (sortedArray[i]["description"].toLowerCase().includes("deposit"))
          countDeposit += sortedArray[i]["sum"];
        else if (
          sortedArray[i]["description"].toLowerCase().includes("withdrawal")
        )
          countWithdrawal += sortedArray[i]["sum"];
        // let date = new Date();
        // date.setHours(20);
        // date.setMinutes(0);
        // date.setSeconds(0);
        // date.setMilliseconds(0);
        // date = date.toISOString();
        // let today = date === sortedArray[i].executionDate;
        arr.push(
          <ConversionRow
            // odd={i % 2 === 0}
            // today={today}
            key={i}
            setEdit={setShowEditAgent}
            // onClick={() => setShowEditAgent(sortedArray[i])}
            data={sortedArray[i]}
          />
        );
      }
    }
    return [arr, countDeposit, countWithdrawal];
  }, [todos, sortBy, sortDirection]);

  const deleteConversionFunc = async () => {
    dispatch(deleteTodo(showDeleteConversion)).then((res) => {
      if (!res.payload?.error) {
        toast.success("Successful!");
      }
    });
  };

  return (
    <MainDiv className="App">
      <FlexRowBetween style={{ margin: "20px auto" }}>
        <FlexRowBetween
          style={{ justifyContent: "flex-start", width: "min-content" }}
        >
          <GenericButton
            onClick={() => setShowAddAgent(true)}
            style={{ marginLeft: "20px", width: "max-content" }}
          >
            Add <img src={plus} style={{ width: "20px", marginLeft: "10px" }} />
          </GenericButton>
          <GenericButton
            onClick={() => history.push(ClientRoutes.User.agents)}
            style={{ marginLeft: "20px", width: "max-content" }}
          >
            Agents
          </GenericButton>
        </FlexRowBetween>

        <GenericButton
          onClick={() => setShowSignOut(true)}
          style={{ marginRight: "20px" }}
        >
          Sign Out
        </GenericButton>
      </FlexRowBetween>
      <FlexRowBetween
        style={{
          margin: "10px auto",
          justifyContent: "flex-start",
        }}
      >
        <OverlayTrigger
          placement={"top"}
          overlay={<Tooltip>{"Total"}</Tooltip>}
        >
          <div
            style={{
              backgroundColor: "#e8f8f5",
              padding: "5px 10px",
              borderRadius: "5px",
              marginLeft: "20px",
              color: "#017c64",
              boxShadow: "0 1px 4px silver",
            }}
          >
            {todosArray[1]}
            {authenticated ? (user.isPoints ? "" : "$") : isPoints ? "" : "$"}
          </div>
        </OverlayTrigger>
        <OverlayTrigger
          placement={"top"}
          overlay={<Tooltip>{"Total"}</Tooltip>}
        >
          <div
            style={{
              backgroundColor: "#f9eaee",
              padding: "5px 10px",
              // fontWeight: "600",
              borderRadius: "5px",
              marginLeft: "20px",
              color: "#cb2e50",
              boxShadow: "0 1px 4px silver",
            }}
          >
            {todosArray[2]}
            {authenticated ? (user.isPoints ? "" : "$") : isPoints ? "" : "$"}
          </div>
        </OverlayTrigger>
      </FlexRowBetween>
      <div
        style={{
          width: "100%",
          height: "100%",
          margin: "0 0",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {todosArray[0]}
      </div>

      {showSignOut && (
        <GenericModal
          init={setShowSignOut}
          div={<LogOutAll init={setShowSignOut} />}
        />
      )}

      {showAddAgent && (
        <GenericModal
          init={setShowAddAgent}
          div={<Add init={setShowAddAgent} />}
        />
      )}
      {showEditAgent && (
        <GenericModal
          init={setShowEditAgent}
          div={
            <Edit
              setShowDeleteConversion={setShowDeleteConversion}
              data={showEditAgent}
              init={setShowEditAgent}
            />
          }
        />
      )}
      {showDeleteConversion && (
        <GenericModal
          init={setShowDeleteConversion}
          div={
            <YesNoModal
              color={"#527bfe"}
              title={"Delete Conversion"}
              body={"Are you sure?"}
              no={setShowDeleteConversion}
              yes={deleteConversionFunc}
            />
          }
        />
      )}
      {todosLoading && <OpacityLoading />}
    </MainDiv>
  );
};

export default Conversions;
