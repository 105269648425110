import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutSpecific } from "../../../store/reducers/authReducer";
import { toast } from "react-toastify";

import { CenteredForm, FlexRowBetween, GenericButton, MyHr } from "../Style";

const LogOutAll = (props) => {
  const { user } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const handleClose = () => {
    props.init(null);
  };
  const logout = () => {
    handleClose();
    dispatch(logoutSpecific()).then((res) => {
      if (!res.payload?.error) {
        toast.success("Successful!");
      } else toast.error(res.payload?.error);
    });
  };

  return (
    <CenteredForm onClick={(e) => e.stopPropagation()}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <div style={{ width: "100%", marginTop: "30px" }}>
          <div
            style={{
              textAlign: "center",
              fontSize: "1.2rem",
              marginBottom: "10px",
            }}
          >
            {"Sign Out"}
          </div>
          <MyHr />
        </div>
        <div style={{ width: "100%", marginTop: "30px" }}>
          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            {"Are you sure?"}
          </div>
          <MyHr />
        </div>
        <div style={{ width: "100%" }}>
          <FlexRowBetween style={{ width: "90%", margin: "20px auto" }}>
            <GenericButton
              style={{
                // backgroundColor: user.color ? user.color : "#DEDEE0",
                padding: "9px 25px",
              }}
              onClick={logout}
            >
              Yes
            </GenericButton>
            <GenericButton
              style={{
                backgroundColor: "gray",
                padding: "9px 25px",
              }}
              onClick={handleClose}
            >
              No
            </GenericButton>
          </FlexRowBetween>
        </div>
      </div>
    </CenteredForm>
  );
};

export default LogOutAll;
