import React, { useEffect, useMemo, useState } from "react";
import {
  DarkenBackgroundImage,
  DisplayNoneComputer,
  DisplayNonePhone,
  FlexRowBetween,
  FlexRowCulumnBetween,
  GenericButton,
  GuestRowStyle,
  MainDiv,
  RespFont,
} from "../common/Style";
import { useDispatch, useSelector } from "react-redux";
import { cleanAll, getGuestsByUid } from "../../store/reducers/guestsReducer";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { toast } from "react-toastify";
import AgentRowC from "../user/agents/AgentRowC";
import OpacityLoading from "../common/loading/OpacityLoading";
import downArrowB from "../../assets/svgs/downArrowB.svg";
import success1 from "../../assets/gifs/success1.gif";
import failure1 from "../../assets/gifs/failure.gif";
import peakpx from "../../assets/pics/b1.jpeg";
import downArrowW from "../../assets/svgs/downArrowW.svg";
import upArrowB from "../../assets/svgs/upArrowB.svg";
import upArrowW from "../../assets/svgs/upArrowW.svg";
import AgentRowP from "../user/agents/AgentRowP";
import Login from "./Login";
import GenericModal from "../common/modals/GenericModal";
import {
  getData,
  setUserPassReduxOnly,
} from "../../store/reducers/dataReducer";
import { UseInterval } from "../common/others/UseInterval";
import { commafy } from "../common/others/Functions";
import Event from "../common/modals/Event";

const TableScore = (props) => {
  const { guests, guestsError, guestsLoading } = useSelector(
    (state) => state.guestsReducer
  );
  const { user, authenticated } = useSelector((state) => state.authReducer);
  const { data, index, msg, userId, userPass, isPoints } = useSelector(
    (state) => state.dataReducer
  );
  let widthS = window.innerWidth; // 1728
  UseInterval(async () => {
    if (userPass)
      dispatch(getData(userPass)).then((res) => {
        if (res.payload?.error) {
          toast.error(res.payload.error);
        }
      });
  }, 30000);

  const [sortBy, setSortBy] = useState("monthlyScore");
  const [pass, setPass] = useState("");
  const [sortDirection, setSortDirection] = useState(true);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showGif, setShowGif] = useState(false);

  const dispatch = useDispatch();
  const getAll = () => dispatch(getGuestsByUid(userId));

  const handleClick = (e) => {
    if (e.type === "contextmenu") {
      setShowSignIn(true);
      // console.log("Right click");
    }
  };

  useEffect(() => {
    if (index > 1) {
      setShowGif(true);
      setTimeout(function () {
        setShowGif(false);
        getAll();
      }, 8000);
    }
  }, [index]);

  useEffect(() => {
    if (userPass)
      dispatch(getData(userPass)).then((res) => {
        if (res.payload?.error) {
          toast.error(res.payload.error);
        }
      });
    return () => {
      dispatch(cleanAll());
    };
  }, [userPass]);

  useEffect(() => {
    if (userId) getAll();
    return () => {
      dispatch(cleanAll());
    };
  }, [userId]);

  useEffect(() => {
    if (guestsError) toast.error(guestsError);
  }, [guestsError]);

  let guestsArray = useMemo(() => {
    let arr = [];
    let dailyTotal = 0;
    let monthlyTotal = 0;
    let sortedArray = [];
    if (Object.keys(guests).length > 0) {
      for (const [key, value] of Object.entries(guests))
        sortedArray.push(value);

      switch (sortBy) {
        case "ratio":
          if (sortDirection) {
            sortedArray.sort(
              (a, b) =>
                b["monthlyScore"] / b["monthlyTarget"] -
                a["monthlyScore"] / a["monthlyTarget"]
            );
          } else
            sortedArray.sort(
              (a, b) =>
                a["monthlyScore"] / a["monthlyTarget"] -
                b["monthlyScore"] / b["monthlyTarget"]
            );
          break;
        default:
          if (sortDirection) sortedArray.sort((a, b) => b[sortBy] - a[sortBy]);
          else sortedArray.sort((a, b) => a[sortBy] - b[sortBy]);
      }
      for (let i = 0; i < sortedArray.length; i++) {
        dailyTotal += sortedArray[i]["dailyScore"];
        monthlyTotal += sortedArray[i]["monthlyScore"];
        arr.push(
          widthS > 768 ? (
            <AgentRowC
              key={i}
              setEdit={() => console.log("should edit")}
              data={sortedArray[i]}
            />
          ) : (
            <AgentRowP
              key={i}
              setEdit={() => console.log("should edit")}
              data={sortedArray[i]}
            />
          )
        );
      }
    }
    return [arr, dailyTotal, monthlyTotal];
  }, [guests, sortBy, sortDirection, userPass]);

  return (
    <>
      {userPass === null ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input onChange={(e) => setPass(e.target.value)} />

          <GenericButton
            onClick={() => dispatch(setUserPassReduxOnly(pass))}
            style={{ width: "60%", margin: "30px auto" }}
          >
            Submit
          </GenericButton>
          {/* <div onClick={() => dispatch(setUserPassReduxOnly(pass))}>submit</div> */}
        </div>
      ) : (
        <MainDiv
          className="App"
          style={{
            position: "relative",
            backgroundImage: `url(${peakpx})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "100%",
          }}
        >
          <DisplayNonePhone style={{ width: "100%" }}>
            <FlexRowBetween
              style={{
                width: "97%",
                margin: "10px auto",
                position: "relative",
              }}
            >
              <FlexRowBetween
                style={{
                  width: widthS > 768 ? "100%" : "90%",
                  margin: "0px auto",
                  alignItems: "baseline",
                }}
              >
                <FlexRowBetween
                  style={{ justifyContent: "flex-start", width: "min-content" }}
                >
                  <FlexRowBetween
                    style={{
                      width: "min-content",
                      border: "2px solid rgba(0, 0, 0, 0.11)",
                      borderRadius: "60px",
                      padding: widthS > 768 ? "5px" : "2px",
                      fontWeight: "700",

                      // background: "rgba(255,255,255,0.9)",
                      // marginRight: "2.5%",
                    }}
                  >
                    <RespFont
                      pSize={"12px"}
                      onClick={() => setSortBy("dailyScore")}
                      style={{
                        padding: widthS > 768 ? "7px 12px" : "4px 8px",
                        cursor: "pointer",
                        backgroundColor:
                          sortBy === "dailyScore" ? "#527bfe" : "inherit",
                        transition: "all 0.2s",
                        color: sortBy === "dailyScore" ? "#fff" : "#3A3C3F",
                        borderRadius: "60px",
                        width: "max-content",
                      }}
                    >
                      {isPoints ? "Daily FTD" : "Daily score"}
                    </RespFont>
                    <RespFont
                      pSize={"12px"}
                      onClick={() => setSortBy("monthlyScore")}
                      style={{
                        padding: widthS > 768 ? "7px 12px" : "4px 8px",
                        cursor: "pointer",
                        backgroundColor:
                          sortBy === "monthlyScore" ? "#527bfe" : "inherit",
                        transition: "all 0.2s",
                        color: sortBy === "monthlyScore" ? "#fff" : "#3A3C3F",
                        borderRadius: "60px",
                        width: "max-content",
                      }}
                    >
                      {isPoints ? "Monthly FTD" : "Monthly score"}
                    </RespFont>
                    <RespFont
                      pSize={"12px"}
                      onClick={() => setSortBy("ratio")}
                      style={{
                        padding: widthS > 768 ? "7px 12px" : "4px 8px",
                        cursor: "pointer",
                        backgroundColor:
                          sortBy === "ratio" ? "#527bfe" : "inherit",
                        transition: "all 0.2s",
                        color: sortBy === "ratio" ? "#fff" : "#3A3C3F",
                        borderRadius: "60px",
                        width: "max-content",
                      }}
                    >
                      Ratio
                    </RespFont>
                  </FlexRowBetween>

                  <FlexRowBetween
                    style={{
                      width: "min-content",
                      border: "2px solid rgba(0, 0, 0, 0.11)",
                      borderRadius: "5px",
                      padding: "3px",
                      fontWeight: "700",
                      marginLeft: widthS > 768 ? "20px" : "10px",
                    }}
                  >
                    <RespFont
                      onClick={() => setSortDirection(true)}
                      style={{
                        padding: widthS > 768 ? "3px 5px" : "0px 4px",
                        cursor: "pointer",
                        backgroundColor: sortDirection ? "#527bfe" : "inherit",
                        transition: "all 0.2s",
                        color: sortDirection ? "#fff" : "#3A3C3F",
                        borderRadius: "3px",
                        width: "max-content",
                      }}
                    >
                      <img
                        src={sortDirection ? upArrowW : upArrowB}
                        style={{ width: widthS > 768 ? "22px" : "13px" }}
                      />
                    </RespFont>
                    <RespFont
                      onClick={() => setSortDirection(false)}
                      style={{
                        padding: widthS > 768 ? "3px 5px" : "0px 4px",
                        cursor: "pointer",
                        backgroundColor: !sortDirection ? "#527bfe" : "inherit",
                        transition: "all 0.2s",
                        color: !sortDirection ? "#fff" : "#3A3C3F",
                        borderRadius: "3px",
                        width: "max-content",
                      }}
                    >
                      <img
                        src={sortDirection ? downArrowB : downArrowW}
                        style={{ width: widthS > 768 ? "22px" : "13px" }}
                      />
                    </RespFont>
                  </FlexRowBetween>
                </FlexRowBetween>

                <div style={{ width: "min-content" }}>
                  <RespFont
                    pSize={"30px"}
                    cSize={"50px"}
                    onContextMenu={handleClick}
                    style={{
                      fontWeight: "700",
                      marginTop: widthS > 768 ? "0" : "-30px",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    {isPoints ? "Conversion" : "Retention"}
                  </RespFont>
                  <RespFont
                    pSize={"25px"}
                    cSize={"45px"}
                    style={{
                      fontWeight: "600",
                      color: "#2f6beb",
                      marginBottom: widthS > 768 ? "0" : "10px",
                      width: "max-content",
                      textAlign: "center",
                    }}
                  >
                    {msg}
                  </RespFont>
                </div>
                <FlexRowBetween
                  style={{
                    width: widthS > 768 ? "max-content" : "90%",
                    // marginLeft: widthS > 768 ? "0" : "5%",
                    justifyContent: "center",
                    // marginTop: widthS > 768 ? "15px" : "0px",
                  }}
                >
                  <RespFont cSize={"20px"} style={{ color: "#fff" }}>
                    Summary Sales:
                  </RespFont>
                  <OverlayTrigger
                    placement={"top"}
                    overlay={<Tooltip>{"Daily total"}</Tooltip>}
                  >
                    <RespFont
                      cSize={"20px"}
                      // pSize={"12px"}
                      style={{
                        width: "max-content",
                        marginLeft: "15px",
                        // marginLeft: widthS > 768 ? "15px" : "0px",
                        padding: widthS > 768 ? "5px 10px" : "3px 7px",
                        borderRadius: "5px",
                        boxShadow: "0 1px 4px silver",
                        color:
                          guestsArray[1] > 0
                            ? "#017c64"
                            : guestsArray[1] < 0
                            ? "#cb2e50"
                            : "black",
                        backgroundColor:
                          guestsArray[1] > 0
                            ? "#e8f8f5"
                            : guestsArray[1] < 0
                            ? "#f9eaee"
                            : "#fff",
                      }}
                    >
                      <b>Today</b>: {commafy(guestsArray[1])}
                      {authenticated
                        ? user.isPoints
                          ? ""
                          : "$"
                        : isPoints
                        ? ""
                        : "$"}
                    </RespFont>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement={"top"}
                    overlay={<Tooltip>{"Monthly total"}</Tooltip>}
                  >
                    <RespFont
                      cSize={"20px"}
                      // pSize={"12px"}
                      style={{
                        width: "max-content",
                        marginLeft: "15px",
                        // marginTop: widthS > 768 ? "10px" : "15px",
                        padding: widthS > 768 ? "5px 10px" : "3px 7px",
                        borderRadius: "5px",
                        boxShadow: "0 1px 4px silver",
                        color:
                          guestsArray[2] > 0
                            ? "#017c64"
                            : guestsArray[2] < 0
                            ? "#cb2e50"
                            : "black",
                        backgroundColor:
                          guestsArray[2] > 0
                            ? "#e8f8f5"
                            : guestsArray[2] < 0
                            ? "#f9eaee"
                            : "#fff",
                      }}
                    >
                      <b>Monthly</b>: {commafy(guestsArray[2])}
                      {authenticated
                        ? user.isPoints
                          ? ""
                          : "$"
                        : isPoints
                        ? ""
                        : "$"}
                    </RespFont>
                  </OverlayTrigger>
                </FlexRowBetween>
              </FlexRowBetween>
            </FlexRowBetween>
          </DisplayNonePhone>
          <FlexRowCulumnBetween style={{ margin: "0px auto" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: widthS > 768 ? "50%" : "100%",
              }}
            >
              <div
                style={{
                  width: widthS > 768 ? "200%" : "100%",
                  height: "100%",
                  margin: "0 0",
                  marginBottom: "30px",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {widthS > 768 ? (
                  <GuestRowStyle
                    style={{
                      margin: "10px auto",
                      boxShadow: `0 0px 2px #527bfe`,
                    }}
                    background={"rgba(255,255,255,0.9)"}
                  >
                    <FlexRowBetween
                      style={{
                        padding: `10px 10px`,
                        cursor: "pointer",
                        textAlign: "left",
                        background: "rgba(255,255,255,0.9)",
                        fontSize: "20px",
                      }}
                    >
                      <div
                        style={{
                          color: "#2f6beb",
                          fontWeight: "600",
                          width: "max-content",
                          fontSize: "20px",
                          // marginLeft: "25px",
                        }}
                      >
                        Agent
                      </div>
                      <div style={{ width: "max-content", marginLeft: "22%" }}>
                        {isPoints ? "Daily FTD" : "Daily score"}
                      </div>
                      <div style={{ width: "max-content", marginRight: "0%" }}>
                        {isPoints ? "Monthly FTD" : "Monthly score"}
                      </div>
                      <div style={{ width: "max-content", marginRight: "6%" }}>
                        Monthly target
                      </div>
                      <div style={{ width: "max-content", marginRight: "10%" }}>
                        Ratio
                      </div>
                    </FlexRowBetween>
                  </GuestRowStyle>
                ) : (
                  <GuestRowStyle
                    style={{
                      margin: "10px auto",
                      marginTop: "0",
                      boxShadow: `0 0px 2px #527bfe`,
                    }}
                    background={"rgba(255,255,255,0.7)"}
                  >
                    <FlexRowBetween
                      style={{
                        padding: `10px 10px`,
                        cursor: "pointer",
                        textAlign: "left",
                      }}
                    >
                      <div
                        style={{
                          color: "#739bf2",
                          fontWeight: "600",
                          width: "32%",
                        }}
                      >
                        Agent
                      </div>
                      <div style={{ width: "33%" }}>
                        <div>{isPoints ? "Daily FTD" : "Daily score"}</div>
                        <div>{isPoints ? "Monthly FTD" : "Monthly score"}</div>
                      </div>
                      <div style={{ width: "30%", textAlign: "center" }}>
                        <div>Monthly target</div>
                        <div>Ratio</div>
                      </div>
                    </FlexRowBetween>
                  </GuestRowStyle>
                )}

                {guestsArray[0]}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                marginTop: widthS > 768 ? "0" : "10px",
                width: widthS > 768 ? "35%" : "100%",
              }}
            >
              <DisplayNoneComputer>
                <RespFont
                  pSize={"30px"}
                  cSize={"50px"}
                  onContextMenu={handleClick}
                  style={{
                    fontWeight: "700",
                    marginTop: widthS > 768 ? "0" : "0px",
                    color: "#fff",
                  }}
                >
                  {isPoints ? "Conversion" : "Retention"}
                </RespFont>
                <RespFont
                  pSize={"25px"}
                  cSize={"45px"}
                  style={{
                    fontWeight: "600",
                    color: "#2f6beb",
                    marginBottom: widthS > 768 ? "0" : "10px",
                  }}
                >
                  {msg}
                </RespFont>
                <FlexRowBetween
                  style={{
                    width: widthS > 768 ? "100%" : "90%",
                    marginLeft: widthS > 768 ? "0" : "5%",
                    justifyContent: "center",
                    marginTop: widthS > 768 ? "15px" : "0px",
                  }}
                >
                  <RespFont cSize={"20px"} style={{ color: "#fff" }}>
                    Summary Sales:
                  </RespFont>
                  <OverlayTrigger
                    placement={"top"}
                    overlay={<Tooltip>{"Daily total"}</Tooltip>}
                  >
                    <RespFont
                      // pSize={"12px"}
                      style={{
                        width: "max-content",
                        marginLeft: "15px",
                        // marginLeft: widthS > 768 ? "15px" : "0px",
                        padding: widthS > 768 ? "5px 10px" : "3px 7px",
                        borderRadius: "5px",
                        boxShadow: "0 1px 4px silver",
                        color:
                          guestsArray[1] > 0
                            ? "#017c64"
                            : guestsArray[1] < 0
                            ? "#cb2e50"
                            : "black",
                        backgroundColor:
                          guestsArray[1] > 0
                            ? "#e8f8f5"
                            : guestsArray[1] < 0
                            ? "#f9eaee"
                            : "#fff",
                      }}
                    >
                      <b>Today</b>: {commafy(guestsArray[1])}
                      {authenticated
                        ? user.isPoints
                          ? ""
                          : "$"
                        : isPoints
                        ? ""
                        : "$"}
                    </RespFont>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement={"top"}
                    overlay={<Tooltip>{"Monthly total"}</Tooltip>}
                  >
                    <RespFont
                      // pSize={"12px"}
                      style={{
                        width: "max-content",
                        marginLeft: "15px",
                        // marginTop: widthS > 768 ? "10px" : "15px",
                        padding: widthS > 768 ? "5px 10px" : "3px 7px",
                        borderRadius: "5px",
                        boxShadow: "0 1px 4px silver",
                        color:
                          guestsArray[2] > 0
                            ? "#017c64"
                            : guestsArray[2] < 0
                            ? "#cb2e50"
                            : "black",
                        backgroundColor:
                          guestsArray[2] > 0
                            ? "#e8f8f5"
                            : guestsArray[2] < 0
                            ? "#f9eaee"
                            : "#fff",
                      }}
                    >
                      <b>Monthly</b>: {commafy(guestsArray[2])}
                      {authenticated
                        ? user.isPoints
                          ? ""
                          : "$"
                        : isPoints
                        ? ""
                        : "$"}
                    </RespFont>
                  </OverlayTrigger>
                </FlexRowBetween>
              </DisplayNoneComputer>
              <DisplayNoneComputer>
                <FlexRowBetween
                  style={{
                    width: widthS > 768 ? "100%" : "90%",
                    margin: "15px auto",
                    marginTop: "15px",
                    justifyContent: "flex-start",
                  }}
                >
                  <FlexRowBetween
                    style={{
                      width: "min-content",
                      border: "2px solid rgba(0, 0, 0, 0.11)",
                      borderRadius: "60px",
                      padding: widthS > 768 ? "5px" : "2px",
                      fontWeight: "700",
                      // marginRight: "2.5%",
                    }}
                  >
                    <RespFont
                      pSize={"12px"}
                      onClick={() => setSortBy("dailyScore")}
                      style={{
                        padding: widthS > 768 ? "7px 12px" : "4px 8px",
                        cursor: "pointer",
                        backgroundColor:
                          sortBy === "dailyScore" ? "#527bfe" : "inherit",
                        transition: "all 0.2s",
                        color: sortBy === "dailyScore" ? "#fff" : "#3A3C3F",
                        borderRadius: "60px",
                        width: "max-content",
                      }}
                    >
                      {isPoints ? "Daily FTD" : "Daily score"}
                    </RespFont>
                    <RespFont
                      pSize={"12px"}
                      onClick={() => setSortBy("monthlyScore")}
                      style={{
                        padding: widthS > 768 ? "7px 12px" : "4px 8px",
                        cursor: "pointer",
                        backgroundColor:
                          sortBy === "monthlyScore" ? "#527bfe" : "inherit",
                        transition: "all 0.2s",
                        color: sortBy === "monthlyScore" ? "#fff" : "#3A3C3F",
                        borderRadius: "60px",
                        width: "max-content",
                      }}
                    >
                      {isPoints ? "Monthly FTD" : "Monthly score"}
                    </RespFont>
                    <RespFont
                      pSize={"12px"}
                      onClick={() => setSortBy("ratio")}
                      style={{
                        padding: widthS > 768 ? "7px 12px" : "4px 8px",
                        cursor: "pointer",
                        backgroundColor:
                          sortBy === "ratio" ? "#527bfe" : "inherit",
                        transition: "all 0.2s",
                        color: sortBy === "ratio" ? "#fff" : "#3A3C3F",
                        borderRadius: "60px",
                        width: "max-content",
                      }}
                    >
                      Ratio
                    </RespFont>
                  </FlexRowBetween>

                  <FlexRowBetween
                    style={{
                      width: "min-content",
                      border: "2px solid rgba(0, 0, 0, 0.11)",
                      borderRadius: "5px",
                      padding: "3px",
                      fontWeight: "700",
                      marginLeft: widthS > 768 ? "20px" : "10px",
                    }}
                  >
                    <RespFont
                      onClick={() => setSortDirection(true)}
                      style={{
                        padding: widthS > 768 ? "3px 5px" : "0px 4px",
                        cursor: "pointer",
                        backgroundColor: sortDirection ? "#527bfe" : "inherit",
                        transition: "all 0.2s",
                        color: sortDirection ? "#fff" : "#3A3C3F",
                        borderRadius: "3px",
                        width: "max-content",
                      }}
                    >
                      <img
                        src={sortDirection ? upArrowW : upArrowB}
                        style={{ width: widthS > 768 ? "22px" : "13px" }}
                      />
                    </RespFont>
                    <RespFont
                      onClick={() => setSortDirection(false)}
                      style={{
                        padding: widthS > 768 ? "3px 5px" : "0px 4px",
                        cursor: "pointer",
                        backgroundColor: !sortDirection ? "#527bfe" : "inherit",
                        transition: "all 0.2s",
                        color: !sortDirection ? "#fff" : "#3A3C3F",
                        borderRadius: "3px",
                        width: "max-content",
                      }}
                    >
                      <img
                        src={sortDirection ? downArrowB : downArrowW}
                        style={{ width: widthS > 768 ? "22px" : "13px" }}
                      />
                    </RespFont>
                  </FlexRowBetween>
                </FlexRowBetween>
              </DisplayNoneComputer>
            </div>
          </FlexRowCulumnBetween>
          {guestsLoading && <OpacityLoading />}

          {showGif && (
            <GenericModal
              init={setShowGif}
              div={
                <Event name={guests[data.uid]?.nickname} amount={data.sum} />
              }
              // div={<Event name={guests[data.uid]["nickname"]} amount={data.sum} />}
            />
          )}
          {showSignIn && (
            <GenericModal
              init={setShowSignIn}
              div={<Login init={setShowSignIn} />}
            />
          )}
        </MainDiv>
      )}
    </>
  );
};

export default TableScore;
