import React, { useState } from "react";
import {
  CenteredForm,
  GenericButton,
  InputBox,
  RespFont,
} from "../../common/Style";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addGuest } from "../../../store/reducers/guestsReducer";
import OpacityLoading from "../../common/loading/OpacityLoading";
import Dropdown from "react-bootstrap/Dropdown";
import { addTodo } from "../../../store/reducers/todosReducer";
import { toast } from "react-toastify";

const Add = (props) => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const { authenticated } = useSelector((state) => state.authReducer);
  const { isPoints } = useSelector((state) => state.dataReducer);
  const [agent, setAgent] = useState("");
  const [description, setDescription] = useState("");
  const { user } = useSelector((state) => state.authReducer);
  let widthS = window.innerWidth; // 1728
  const { todosLoading, n2u } = useSelector((state) => state.todosReducer);
  const ops = Object.keys(n2u).map((item, index) => (
    <Dropdown.Item key={index} onClick={() => setAgent(item)}>
      {item}
    </Dropdown.Item>
  ));

  const onSubmit = async (data) => {
    if (agent === "") toast.error("Agent field is required");
    else {
      let sum = parseFloat(data.sum);
      if (
        data.description.toLowerCase().includes("deposit") &&
        parseFloat(data.sum) < 0
      )
        sum *= -1;
      else if (
        data.description.toLowerCase().includes("withdrawal") &&
        parseFloat(data.sum) > 0
      )
        sum *= -1;
      const body = {
        uid: n2u[agent],
        description: data.description,
        sum,
        dateTime:
          data.dateTime === ""
            ? new Date().toISOString()
            : data.dateTime.toString(),
      };
      dispatch(addTodo(body)).then((res) => {
        if (!res.payload?.error) {
          toast.success("Successful!");
          props.init();
        }
      });
    }
  };
  return (
    <CenteredForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      style={{
        paddingTop: "10px",
        paddingBottom: "10px",
        background: "#f2f2f2cc",
      }}
    >
      <RespFont
        pSize={"20px"}
        cSize={"22px"}
        style={{
          textAlign: "center",
          color: "#3A3C3F",
          fontWeight: "bold",
          padding: `30px 0`,
          paddingBottom: `10px`,
        }}
      >
        New Conversion
      </RespFont>

      <InputBox labelColor={"gray"} color={"#3A3C3F"}>
        <div className="relativeBox">
          <input
            value={agent}
            onChange={(e) => setAgent(e.target.value)}
            type="text"
            name="agent"
            disabled={true}
            minLength={2}
            maxLength={50}
            required={true}
            ref={register()}
          />
          <label
            style={{
              top: "-20px",
              fontSize: widthS > 768 ? "12px" : "11px",
              color: "gray",
            }}
          >
            agent
          </label>
          <Dropdown
            style={{
              position: "absolute",
              right: "0px",
              top: "16px",
            }}
          >
            <Dropdown.Toggle
              style={{
                background: "#527bfe",
                zIndex: 1,
                border: "none",
                height: "26px",
                width: "20px",
                borderRadius: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              id="dropdown-basic"
            ></Dropdown.Toggle>
            <Dropdown.Menu style={{ textAlign: "left", zIndex: 2 }}>
              {ops}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="relativeBox">
          <input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            name="description"
            minLength={2}
            maxLength={50}
            required={true}
            ref={register()}
          />
          <label>description</label>
          <Dropdown
            style={{
              position: "absolute",
              right: "0px",
              top: "16px",
            }}
          >
            <Dropdown.Toggle
              style={{
                background: "#527bfe",
                zIndex: 1,
                border: "none",
                height: "26px",
                width: "20px",
                borderRadius: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              id="dropdown-basic"
            ></Dropdown.Toggle>
            <Dropdown.Menu style={{ textAlign: "left" }}>
              <Dropdown.Item onClick={() => setDescription("deposit")}>
                {"deposit"}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setDescription("withdrawal")}>
                {"withdrawal"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="relativeBox">
          <input
            type="number"
            name="sum"
            required={true}
            min={-100000000000}
            max={100000000000}
            ref={register()}
          />
          <label>
            sum
            {authenticated
              ? user.isPoints
                ? ""
                : "($)"
              : isPoints
              ? ""
              : " ($)"}
          </label>
        </div>

        <div className="relativeBox">
          <input type="datetime-local" name="dateTime" ref={register()} />
          <label>time</label>
        </div>

        <div style={{ paddingBottom: "10px" }}>
          <GenericButton
            type="submit"
            style={{ width: "60%", margin: "30px auto" }}
          >
            Submit
          </GenericButton>
        </div>
      </InputBox>
      {todosLoading && <OpacityLoading />}
    </CenteredForm>
  );
};

export default Add;
