import React from "react";
import { useSelector } from "react-redux";
import { addAlpha, commafy, dateFormat } from "../../common/others/Functions";
import { FlexRowBetween, GuestRowStyle, RespFont } from "../../common/Style";
import { SemiCircleProgress } from "react-progress-component";

const AgentRowP = (props) => {
  const { user } = useSelector((state) => state.authReducer);
  const { authenticated } = useSelector((state) => state.authReducer);
  const { isPoints } = useSelector((state) => state.dataReducer);
  const add_del = () => {
    alert("agent");
    // props.add_del({
    //     _id: props.data._id, closeness: props.data.closeness,
    //     amount: (props.data.accept >= 0 && props.data.accept !== null) ? props.data.accept : props.data.sum
    // });
  };

  const handleClick = (e) => {
    if (e.type === "click") {
      if (props.multiSelect) add_del();
      else props.setEdit(props.data);
    } else if (e.type === "contextmenu") {
      console.log("Right click");
    }
  };
  const getDiv = (data) => {
    return (
      <div
        style={{
          width: "15%",
        }}
      >
        <RespFont
          pSize={"12px"}
          style={{
            width: "min-content",
            padding: "3px 7px",
            borderRadius: "5px",
            boxShadow: "0 1px 4px silver",
            color: data > 0 ? "#017c64" : data < 0 ? "#cb2e50" : "black",
            backgroundColor:
              data > 0 ? "#e8f8f5" : data < 0 ? "#f9eaee" : "#fff",
          }}
        >
          {commafy(data)}
          {authenticated ? (user.isPoints ? "" : "$") : isPoints ? "" : "$"}
        </RespFont>
      </div>
    );
  };

  const per = (
    (props.data.monthlyScore / props.data.monthlyTarget) *
    100
  ).toFixed(0);
  return (
    <GuestRowStyle
      // background={props.checked ? addAlpha(user.color, 0.4) : null}
      background={props.odd ? "#f2f2f2" : "rgba(255,255,255,0.9)"}
    >
      <FlexRowBetween
        onClick={handleClick}
        onContextMenu={handleClick}
        style={{
          padding: `10px 10px`,
          paddingRight: "0px",
          cursor: "pointer",
          textAlign: "center",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div style={{ width: "35%" }}>
          <div
            style={{
              color: "#2f6beb",
              fontWeight: "600",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {props.data.nickname}
          </div>

          <img
            src={
              props.data.pic !== ""
                ? props.data.pic
                : "https://icon-library.com/images/no-user-image-icon/no-user-image-icon-0.jpg"
            }
            style={{ width: "50px", borderRadius: "5px", marginTop: "5px" }}
          />
        </div>

        <div style={{ width: "30%", marginRight: "20px" }}>
          {getDiv(props.data.dailyScore)}
          <div style={{ height: "15px" }} />
          {getDiv(props.data.monthlyScore)}
        </div>

        <div
          style={{
            width: "35%",
            marginRight: "-10px",
          }}
        >
          <div
            style={{
              color: "#739bf2",
              fontWeight: "600",
              textAlign: "center",
              marginBottom: "-5px",
            }}
          >
            {commafy(props.data.monthlyTarget)}
            {authenticated ? (user.isPoints ? "" : "$") : isPoints ? "" : "$"}
          </div>
          <SemiCircleProgress
            strokeBackgroundColor={"silver"}
            strokeColor={
              per < 25
                ? "red"
                : per < 50
                ? "orange"
                : per < 75
                ? "#E7EF10"
                : "#7AC74F"
            }
            progressValue={per}
            strokeWidth={5}
            progressLabel={"%"}
            backgroundColor={"#ffffff00"}
          />
        </div>
      </FlexRowBetween>
    </GuestRowStyle>
  );
};

export default AgentRowP;
