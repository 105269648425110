import axios from "axios";
const ServerUrl = "https://benis-server.herokuapp.com/";
// const ServerUrl = "http://localhost:8000/";

const getGetParameters = (data) => {
  return Object.keys(data)
    .map((key) => `${key}=${data[key]}`)
    .join("&");
};

const getConfig = () => {
  let config = {};
  config.headers = new Headers({
    "Content-Type": "application/json",
  });
  const token = localStorage.getItem("bs");
  if (token) config.headers["Authorization"] = "Bearer " + token;

  return config;
};

export const get2 = async (relativePath, data = {}) => {
  let url = ServerUrl + relativePath;
  // parse object into parameters string
  const urlParameters = getGetParameters(data);
  if (urlParameters !== "")
    // add parameters, only if there is something to add..
    url = url + "?" + urlParameters;
  let headers = { Authorization: "Bearer " + localStorage["bs"] };
  try {
    const { data, error, status } = await axios.get(url, { headers });
    return { data, error, status };
  } catch (err) {
    return { error: err.response.data, status: err.response.status };
  }
};

export const get = async (relativePath, data = {}) => {
  let url = ServerUrl + relativePath;

  // parse object into parameters string
  const urlParameters = getGetParameters(data);
  if (urlParameters !== "")
    // add parameters, only if there is something to add..
    url = url + "?" + urlParameters;
  let headers = { Authorization: "Bearer " + localStorage["bs"] };
  return axios.get(url, { headers }).then(
    (data) => {
      if (data.status !== 200) throw data.data;
      return data.data;
    },
    (serverException) => {
      throw serverException.response.data;
    }
  );
};

export const post = async (relativePath, data = {}) => {
  let url = ServerUrl + relativePath;
  return axios.post(url, data, getConfig()).then(
    (data) => {
      if (data.status !== 200) throw data.data;
      return data.data;
    },
    (serverException) => {
      throw serverException.response.data;
    }
  );
};

export const post2 = async (relativePath, body = {}) => {
  let url = ServerUrl + relativePath;
  try {
    const { data, error, status } = await axios.post(url, body, getConfig());
    return { data, error, status };
  } catch (err) {
    return { error: err.response.data, status: err.response.status };
  }
};

export const uploadImage = async (relativePath, body = {}) => {
  let url = ServerUrl + relativePath;
  let config = getConfig();
  config["Content-Type"] = "multipart/form-data";
  try {
    const { data, error, status } = await axios.post(url, body, config);
    return { data, error, status };
  } catch (err) {
    return { error: err.response.data, status: err.response.status };
  }
};

export const uploadImage1 = async (relativePath, data = {}) => {
  let url = ServerUrl + relativePath;
  let config = getConfig();
  config["Content-Type"] = "multipart/form-data";
  return axios.post(url, data, config).then(
    (data) => {
      if (data.status !== 200) throw data.data;
      return data.data;
    },
    (serverException) => {
      throw serverException.response.data;
    }
  );
};
