import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ClientRoutes from "./Routes";
import TableScore from "../components/unAuth/TableScore";

const UnAuthSwitch = () => {
  return (
    <Switch>
      <Route
        exact={true}
        path={ClientRoutes.UnAuth.tablescore}
        component={(props) => <TableScore {...props} />}
      />
      <Redirect from="/" to={ClientRoutes.UnAuth.tablescore} />
    </Switch>
  );
};

export default UnAuthSwitch;
