import React from "react";
import { useSelector } from "react-redux";
import { addAlpha, dateFormat } from "../../common/others/Functions";
import { FlexRowBetween, GuestRowStyle } from "../../common/Style";

const ConversionRow = (props) => {
  const { user } = useSelector((state) => state.authReducer);
  const { n2u, u2n } = useSelector((state) => state.todosReducer);
  const { authenticated } = useSelector((state) => state.authReducer);
  const { isPoints } = useSelector((state) => state.dataReducer);
  const add_del = () => {
    alert("todo");
    // props.add_del({
    //     _id: props.data._id, closeness: props.data.closeness,
    //     amount: (props.data.accept >= 0 && props.data.accept !== null) ? props.data.accept : props.data.sum
    // });
  };
  console.log(props.data);
  const handleClick = (e) => {
    if (e.type === "click") {
      if (props.multiSelect) add_del();
      else props.setEdit(props.data);
    } else if (e.type === "contextmenu") {
      console.log("Right click");
    }
  };
  return (
    <GuestRowStyle
      // background={props.checked ? addAlpha(user.color, 0.4) : null}
      background={props.odd ? "#f2f2f2" : "rgba(255,255,255,0.7)"}
    >
      <FlexRowBetween
        onClick={handleClick}
        onContextMenu={handleClick}
        style={{ padding: `10px 10px`, cursor: "pointer", textAlign: "center" }}
      >
        <div>{dateFormat(props.data.dateTime)}</div>
        {/* <div>{props.data.dateTime}</div> */}
        <div>{u2n[props.data.uid]}</div>
        <div>{props.data.description}</div>
        <div
          style={{
            backgroundColor: props.data.description
              .toLowerCase()
              .includes("deposit")
              ? "#e8f8f5"
              : props.data.description.toLowerCase().includes("withdrawal")
              ? "#f9eaee"
              : "none",
            color: props.data.description.toLowerCase().includes("deposit")
              ? "#017c64"
              : props.data.description.toLowerCase().includes("withdrawal")
              ? "#cb2e50"
              : "black",
            padding: "5px 10px",
            // fontWeight: "600",
            borderRadius: "5px",
            boxShadow: "0 1px 4px silver",
          }}
        >
          {props.data.sum}
          {authenticated ? (user.isPoints ? "" : "$") : isPoints ? "" : "$"}
        </div>
      </FlexRowBetween>
    </GuestRowStyle>
  );
};

export default ConversionRow;
