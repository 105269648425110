import styled from "styled-components";

const media = {
  phone: "@media only screen and (max-width:480px)",
  phoneAndTablet: "@media only screen and (max-width:768px)",
  tablet: "@media only screen and (min-width:481px) and (max-width:768px)",
  bigTablet: "@media only screen and (min-width:769px) and (max-width:1024px)",
  comp: "@media only screen and (min-width:1025px)",
};

const constants = {
  fontColor: "#3A3C3F",
  // orange
  // buttonsColor: "#f1ae6e",
  // blue
  buttonsColor: "#527bfe",
};

export const MyHr = styled.hr`
  background-color: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  height: 1px;
  width: 90%;
  margin: 0 auto;
`;

export const PlaceHolderImage = styled.img`
  position: absolute;
  right: 0px;
  top: ${(props) => (props.top ? props.top : "50%")};
  width: ${(props) => (props.fontSize ? props.fontSize : "50%")};
  transform: translate(0, -50%);
`;

export const ModalStyle = styled.div`
  color: black;
  display: flex;
  overflow: auto;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  min-height: 100vh;
  height: 100%;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  z-index: 1;
`;

export const CenteredForm = styled.form`
  width: 40%;
  ${media.bigTablet} {
    width: 50%;
  }
  ${media.tablet} {
    width: 60%;
  }
  ${media.phone} {
    width: 80%;
  }
  border-radius: 10px;
  box-shadow: 5px 20px 50px silver;
  background-color: #f2f2f2;
  overflow: auto;
  width: 30%;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.2s;
`;

export const RespFont = styled.div`
  font-size: ${(props) =>
    props.cSize ? props.cSize : "16px"}; // computer size
  ${media.phoneAndTablet} {
    font-size: ${(props) => (props.pSize ? props.pSize : "14px")}; // phone size
  }
`;

export const InputBox = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  .relativeBox {
    position: relative;
    width: 60%;
    margin-left: 20%;
    margin-bottom: 8px;
  }
  input {
    width: 100%;
    font-size: 16px;
    ${media.phoneAndTablet} {
      font-size: 14px;
    }
    padding-top: 25px;
    padding-bottom: 0px;
    color: ${(props) => (props.color ? props.color : "#fff")};
    border: none;
    border-bottom: 1px solid ${(props) => (props.color ? props.color : "#fff")};
    outline: none;
    background: transparent;

    &:focus {
      border-bottom: 1px solid
        ${(props) =>
          props.borderBottomColor
            ? props.borderBottomColor
            : `${constants.fontColor}`};
      transition: 200ms;
    }
  }
  label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 26px 0;
    font-size: 16px;
    ${media.phoneAndTablet} {
      font-size: 14px;
    }
    color: ${(props) => (props.color ? props.color : "#fff")};
    pointer-events: none;
    transition: 0.5s;
  }
  input:focus + label {
    top: -20px;
    left: 0;
    color: ${(props) => (props.labelColor ? props.labelColor : "#d5c5f3")};
    font-size: 12px;
    ${media.phoneAndTablet} {
      font-size: 11px;
    }
  }
  input:valid + label {
    top: -20px;
    left: 0;
    color: ${(props) => (props.labelColor ? props.labelColor : "#d5c5f3")};
    font-size: 12px;
    ${media.phoneAndTablet} {
      font-size: 11px;
    }
  }
`;

export const GenericDiv = styled.div`
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  border: none;
  min-width: max-content;
  background: ${(props) => (props.background ? props.background : "#3A3C3F")};
  color: ${(props) => (props.color ? props.color : "#ffffff")};
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  &:hover {
    transition: all 0.2s;
    background: ${(props) =>
      props.hoverBackground
        ? props.hoverBackground
        : "rgb(218, 216, 216)"} !important;
    color: ${(props) =>
      props.hoverColor ? props.hoverColor : "#3A3C3F"} !important;
  }
`;

export const MainDiv = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  margin: 0 auto;
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  /* background: #f2f2f2; */
  background: #eef2fc;
  position: relative;
`;

export const FlexRowBetween = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
export const FlexRowCulumnBetween = styled(FlexRowBetween)`
  align-items: baseline;
  ${media.phoneAndTablet} {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

// export const DarkenBackgroundImage = styled.div`
//   background: linear-gradient(
//     to right,
//     rgba(0, 0, 0, 0.8),
//     rgba(0, 0, 0, 0.7),
//     rgba(0, 0, 0, 0.6),
//     rgba(0, 0, 0, 0.5),
//     rgba(0, 0, 0, 0.3),
//     rgba(255, 255, 255, 0.6),
//     rgba(255, 255, 255, 0.8),
//     #fff
//   );
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   mix-blend-mode: darken;
// `;

export const GuestRowStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 95%;
  margin-left: 2.5%;
  margin-top: 10px;
  ${media.phoneAndTablet} {
    width: 90%;
    margin-left: 5%;
  }
  background-color: ${(props) =>
    props.background ? props.background : "rgb(245, 245, 245, 0.8)"};
  border-radius: 5px;
  box-shadow: 0 1px 2px silver;

  transition: 200ms;
  &:hover {
    transform: scale(1.03, 1.03);
    transition: 200ms;
  }
`;

export const DisplayNonePhone = styled.div`
  width: 100%;
  display: block;
  ${media.phoneAndTablet} {
    display: none;
  }
`;

export const DisplayNoneComputer = styled.div`
  width: 100%;
  display: none;
  ${media.phoneAndTablet} {
    display: block;
  }
`;

export const GenericButton = styled.button`
  text-decoration: none;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  border: none;
  background: ${(props) =>
    props.background ? props.background : constants.buttonsColor};
  box-shadow: 2px 2px 8px silver;
  color: white;
  padding: 6px 11px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  &:hover {
    transition: all 0.2s;
    outline: none;
    background: rgba(223, 223, 223) !important;
    color: #3a3c3f;
    box-shadow: 0px 2px 4px gray;
  }
`;
