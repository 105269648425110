import React, { useState } from "react";
import {
  CenteredForm,
  GenericButton,
  InputBox,
  PlaceHolderImage,
  RespFont,
} from "../common/Style";
import { useForm } from "react-hook-form";
import { login } from "../../store/reducers/authReducer";
import { sha512 } from "js-sha512";
import { useDispatch } from "react-redux";
import eye from "../../assets/svgs/eye.svg";
import { toast } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [showPass, setShowPass] = useState(false);

  const onSubmit = async (data) => {
    const body = {
      nickname: data.nickname,
      password: sha512(data.password),
    };
    dispatch(login(body)).then((res) => {
      if (!res.payload?.error) {
        toast.success("Successful!");
      } else toast.error(res.payload?.error);
    });
  };
  return (
    <CenteredForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      style={{
        paddingTop: "10px",
        paddingBottom: "10px",
        background: "#f2f2f2cc",
      }}
    >
      <RespFont
        pSize={"20px"}
        cSize={"22px"}
        style={{
          textAlign: "center",
          color: "#3A3C3F",
          fontWeight: "bold",
          padding: `30px 0`,
          paddingBottom: `10px`,
        }}
      >
        Sign In
      </RespFont>

      <InputBox labelColor={"gray"} color={"#3A3C3F"}>
        <div className="relativeBox">
          <input
            type="text"
            name="nickname"
            required={true}
            minLength={2}
            maxLength={20}
            ref={register()}
          />
          <label>nickname</label>
        </div>
        <div className="relativeBox">
          <PlaceHolderImage
            onClick={() => setShowPass(!showPass)}
            fontSize={`20px`}
            top={`34px`}
            style={{ cursor: "pointer" }}
            src={eye}
            alt="accept"
          />
          <input
            name="password"
            type={showPass ? "text" : "password"}
            required={true}
            minLength={2}
            maxLength={20}
            ref={register()}
          />
          <label>password</label>
        </div>

        <div style={{ paddingBottom: "10px" }}>
          <GenericButton
            type="submit"
            style={{ width: "60%", margin: "30px auto" }}
          >
            Sign In
          </GenericButton>
        </div>
      </InputBox>
    </CenteredForm>
  );
};

export default Login;
