const ClientRoutes = {
  User: {
    agents: "/agents",
    conversions: "/conversions",
  },
  UnAuth: {
    tablescore: "/tablescore",
  },
};

export default ClientRoutes;
