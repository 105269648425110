import React from "react";
import { FlexRowBetween, GuestRowStyle, RespFont } from "../../common/Style";
import { SemiCircleProgress } from "react-progress-component";
import { commafy } from "../../common/others/Functions";
import { useSelector } from "react-redux";

const AgentRowC = (props) => {
  const { user, authenticated } = useSelector((state) => state.authReducer);
  const { isPoints } = useSelector((state) => state.dataReducer);

  const add_del = () => {
    alert("agent");
    // props.add_del({
    //     _id: props.data._id, closeness: props.data.closeness,
    //     amount: (props.data.accept >= 0 && props.data.accept !== null) ? props.data.accept : props.data.sum
    // });
  };
  const handleClick = (e) => {
    if (e.type === "click") {
      if (props.multiSelect) add_del();
      else props.setEdit(props.data);
    } else if (e.type === "contextmenu") {
      console.log("Right click");
    }
  };
  const getDiv = (data) => {
    return (
      <div
        style={{
          width: "15%",
        }}
      >
        <div
          style={{
            width: "min-content",
            padding: "5px 10px",
            borderRadius: "5px",
            fontSize: "34px",
            boxShadow: "0 1px 4px silver",
            color: data > 0 ? "#017c64" : data < 0 ? "#cb2e50" : "black",
            backgroundColor:
              data > 0 ? "#e8f8f5" : data < 0 ? "#f9eaee" : "none",
          }}
        >
          {commafy(data)}
          {authenticated ? (user.isPoints ? "" : "$") : isPoints ? "" : "$"}
        </div>
      </div>
    );
  };

  const per = (
    (props.data.monthlyScore / props.data.monthlyTarget) *
    100
  ).toFixed(0);
  return (
    <GuestRowStyle
      // background={props.checked ? addAlpha(user.color, 0.4) : null}
      background={props.odd ? "#f2f2f2" : "rgba(255,255,255,0.9)"}
    >
      <FlexRowBetween
        onClick={handleClick}
        onContextMenu={handleClick}
        style={{
          padding: `4px 10px`,
          paddingRight: "0px",
          cursor: "pointer",
          textAlign: "center",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <img
          src={
            props.data.pic !== ""
              ? props.data.pic
              : "https://icon-library.com/images/no-user-image-icon/no-user-image-icon-0.jpg"
          }
          style={{ width: "50px", borderRadius: "5px", marginRight: "20px" }}
        />
        <RespFont
          cSize={"38px"}
          style={{
            color: "#2f6beb",
            fontWeight: "600",
            width: "25%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {props.data.nickname}
        </RespFont>
        {getDiv(props.data.dailyScore)}
        {getDiv(props.data.monthlyScore)}
        <div
          style={{
            width: "20%",
            // textAlign: "right",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              width: "min-content",
              padding: "5px 10px",
              borderRadius: "5px",
              fontSize: "34px",
              boxShadow: "0 1px 4px silver",
              color: "#193cb0",
              backgroundColor: "#e3e9ff",
            }}
          >
            {commafy(props.data.monthlyTarget)}
            {authenticated ? (user.isPoints ? "" : "$") : isPoints ? "" : "$"}
          </div>
        </div>
        <div style={{ width: "120px" }}>
          <SemiCircleProgress
            strokeBackgroundColor={"silver"}
            strokeColor={
              per < 25
                ? "red"
                : per < 50
                ? "orange"
                : per < 75
                ? "#E7EF10"
                : "#7AC74F"
            }
            progressValue={per}
            strokeWidth={6}
            progressLabel={"%"}
            backgroundColor={"#ffffff00"}
          />
        </div>

        {/* <div
          style={{
            width: "20%",
            textAlign: "right",
          }}
        >
          {props.data.monthlyTarget}
        </div> */}
      </FlexRowBetween>
    </GuestRowStyle>
  );
};

export default AgentRowC;
