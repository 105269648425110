import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ClientRoutes from "./Routes";
import Agents from "../components/user/agents/Agents";
import Conversions from "../components/user/conversions/Conversions";

const UserSwitch = () => {
  return (
    <Switch>
      <Route
        path={ClientRoutes.User.agents}
        component={(props) => <Agents {...props} />}
      />
      <Route
        path={ClientRoutes.User.conversions}
        component={(props) => <Conversions {...props} />}
      />
      <Redirect from="/" to={ClientRoutes.User.agents} />
    </Switch>
  );
};

export default UserSwitch;
