import { createSlice } from "@reduxjs/toolkit";
import ServerRoutes from "../api/routes";
import { post, post2, get2 } from "../api/http";

const initialState = {
  guests: {},
  guest: null,
  guestsError: null,
  guestsLoading: false,
};

const _actionStart = (state) => {
  state.guestsLoading = true;
  state.guestsError = null;
};
const _actionSuccess = (state) => {
  state.guestsLoading = false;
  state.guestsError = null;
};
const _actionFailed = (state, action) => {
  state.guestsLoading = false;
  state.guestsError = action.payload.error;
};

const _setGuests = (state, action) => {
  state.guests = action.payload;
};

const _deleteGuests = (state, action) => {
  let editedGuests = { ...state.guests };
  delete editedGuests[action.payload];
  state.guests = editedGuests;
};

const _clean = (state) => {
  state.guests = {};
  state.guest = null;
  state.guestsError = null;
  state.guestsLoading = false;
};

const _setGuest = (state, action) => {
  state.guest = action.payload;
};

const _addGuest = (state, action) => {
  console.log(action.payload);
  let editedGuests = { ...state.guests };
  editedGuests[action.payload.id] = {
    ...action.payload.body,
    _id: action.payload.id,
    dailyScore: 0,
    monthlyScore: 0,
  };
  state.guests = editedGuests;
};

const _editGuest = (state, action) => {
  let editedGuests = { ...state.guests };
  for (const [key, value] of Object.entries(action.payload.body))
    editedGuests[action.payload.id][key] = value;
  state.guests = editedGuests;
};

const _deleteGuest = (state, action) => {
  let editedGuests = { ...state.guests };
  delete editedGuests[action.payload];
  state.guests = editedGuests;
};

const guest = createSlice({
  name: "guest",
  initialState,
  reducers: {
    setGuests: _setGuests,
    setGuest: _setGuest,
    addGuest1: _addGuest,
    editGuest: _editGuest,
    deleteGuest1: _deleteGuest,
    deleteGuests1: _deleteGuests,
    actionStart: _actionStart,
    actionFailed: _actionFailed,
    actionSuccess: _actionSuccess,
    cleanGuestsState: _clean,
  },
});

const { reducer, actions } = guest;

const {
  setGuests,
  setGuest,
  addGuest1,
  editGuest,
  deleteGuest1,
  deleteGuests1,
  actionStart,
  actionFailed,
  actionSuccess,
  cleanGuestsState,
} = actions;

export default reducer;

export const getGuests = () => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await get2(ServerRoutes.Guests.getAll, {});
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(setGuests(data));
    }
  };
};

export const getGuestsByUid = (uid) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(
      ServerRoutes.Guests.getGuestsByUid,
      { uid }
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(setGuests(data));
    }
  };
};

export const getGuest = (id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await get2(ServerRoutes.Guests.getGuest, {
      id,
    });
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(setGuest(data));
    }
  };
};

export const cleanAll = () => {
  return async (dispatch) => {
    dispatch(cleanGuestsState());
  };
};

export const editGuest1 = (id, body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Guests.edit + id, body);
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(editGuest({ id, body }));
    }
  };
};

export const deleteOneGuest = (id) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Guests.delete, { id });
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(deleteGuest1(id));
    }
  };
};

export const deleteGuest = (guestsToDelete) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { error, status } = await post2(ServerRoutes.Guests.deleteGuest, {
      guestsToDelete,
    });
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(deleteGuests1(guestsToDelete));
    }
  };
};

export const addGuest = (body) => {
  return async (dispatch) => {
    dispatch(actionStart());
    const { data, error, status } = await post2(
      ServerRoutes.Guests.addGuest,
      body
    );
    if (status !== 200) return dispatch(actionFailed({ error }));
    else {
      dispatch(actionSuccess());
      return dispatch(addGuest1({ id: data.id, body }));
    }
  };
};

export const deleteMany = (uid) => {
  return async (dispatch) => {
    await post(ServerRoutes.Guests.deleteMany, { uid });
  };
};
