import React from "react";
import {
  CenteredForm,
  FlexRowBetween,
  GenericButton,
  InputBox,
  RespFont,
} from "../common/Style";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import OpacityLoading from "../common/loading/OpacityLoading";
import { toast } from "react-toastify";
import { editDetails } from "../../store/reducers/authReducer";
const EditDetails = (props) => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const { user } = useSelector((state) => state.authReducer);

  const { authLoading } = useSelector((state) => state.authReducer);

  const onSubmit = async (data) => {
    const body = {
      msg: data.msg,
    };
    dispatch(editDetails(body)).then((res) => {
      if (!res.payload?.error) {
        toast.success("Successful!");
        props.init();
      } else toast.error(res.payload?.error);
    });
  };
  return (
    <CenteredForm
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
      style={{
        paddingTop: "10px",
        paddingBottom: "10px",
        background: "#f2f2f2cc",
      }}
    >
      <RespFont
        pSize={"20px"}
        cSize={"22px"}
        style={{
          textAlign: "center",
          color: "#3A3C3F",
          fontWeight: "bold",
          padding: `30px 0`,
          paddingBottom: `10px`,
        }}
      >
        Edit Details
      </RespFont>

      <InputBox labelColor={"gray"} color={"#3A3C3F"}>
        <div className="relativeBox">
          <input
            defaultValue={user.msg}
            type="text"
            name="msg"
            minLength={2}
            maxLength={100}
            required={true}
            ref={register()}
          />
          <label>title</label>
        </div>

        <FlexRowBetween style={{ width: "60%", margin: "30px auto" }}>
          <GenericButton type="submit" style={{ width: "100%" }}>
            Submit
          </GenericButton>
        </FlexRowBetween>
      </InputBox>
      {authLoading && <OpacityLoading />}
    </CenteredForm>
  );
};

export default EditDetails;
