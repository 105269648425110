import authReducer from "./authReducer";
import guestsReducer from "./guestsReducer";
import todosReducer from "./todosReducer";
import dataReducer from "./dataReducer";

export const reducers = {
  todosReducer,
  authReducer,
  dataReducer,
  guestsReducer,
};
